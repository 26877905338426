// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  padding: 13px 20px;
  border-radius: 16px;
}
.close_icon_modal {
  height: 24px;
  width: 24px;
}
#success_modal .modal-header {
  justify-content: center;
  border-bottom: none;
}
#approve_modal {
  max-width: 520px;
}
#claim_modal{
  max-width: 520px;
}
.border_none {
  border: none;
}
.field_array_input {
  margin-bottom: 0px;
}
.delete_field {
  background: transparent;
  border: none;
}
.close_btn {
  top: 7%;
  right: 3%;
}
.closemark {
  position: absolute;
  right: 16px;
  cursor: pointer;
  font-size: 19px;
  color: var(--text-primary);
  /* -webkit-transform: translateY(55%); */
  /* transform: translateY(55%); */
  top: 9px;
}
.goBackIcon {
  font-size: 14px;
  color: var(--text-primary);
  transform: translateY(45%);
  position: relative;
  right: 22px;
}
.modal-body {
  padding: 20px 45px 30px 45px;
  border-radius: 16px;
}

.modal-body-css {
  padding: 0px !important;
  margin: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,YAAY;AACd;AACA;EACE,kBAAkB;AACpB;AACA;EACE,uBAAuB;EACvB,YAAY;AACd;AACA;EACE,OAAO;EACP,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,eAAe;EACf,0BAA0B;EAC1B,wCAAwC;EACxC,gCAAgC;EAChC,QAAQ;AACV;AACA;EACE,eAAe;EACf,0BAA0B;EAC1B,0BAA0B;EAC1B,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,4BAA4B;EAC5B,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,sBAAsB;AACxB","sourcesContent":[".modal-content {\n  padding: 13px 20px;\n  border-radius: 16px;\n}\n.close_icon_modal {\n  height: 24px;\n  width: 24px;\n}\n#success_modal .modal-header {\n  justify-content: center;\n  border-bottom: none;\n}\n#approve_modal {\n  max-width: 520px;\n}\n#claim_modal{\n  max-width: 520px;\n}\n.border_none {\n  border: none;\n}\n.field_array_input {\n  margin-bottom: 0px;\n}\n.delete_field {\n  background: transparent;\n  border: none;\n}\n.close_btn {\n  top: 7%;\n  right: 3%;\n}\n.closemark {\n  position: absolute;\n  right: 16px;\n  cursor: pointer;\n  font-size: 19px;\n  color: var(--text-primary);\n  /* -webkit-transform: translateY(55%); */\n  /* transform: translateY(55%); */\n  top: 9px;\n}\n.goBackIcon {\n  font-size: 14px;\n  color: var(--text-primary);\n  transform: translateY(45%);\n  position: relative;\n  right: 22px;\n}\n.modal-body {\n  padding: 20px 45px 30px 45px;\n  border-radius: 16px;\n}\n\n.modal-body-css {\n  padding: 0px !important;\n  margin: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
