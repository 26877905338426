import { Pie } from 'react-chartjs-2';

function PieChart({
  width, height, labels, value,
}) {
  const data = {
    labels: labels || ['Chips Sale', 'Subscription Sale'],
    datasets: [
      {
        data: value,
        backgroundColor: ['#1a96f0', '#ebeff2'],
        hoverBackgroundColor: ['#1a96f0', '#ebeff2'],
        hoverBorderColor: '#fff',
      },
    ],
  };

  return (
    <div style={{ width, height }}>
      <Pie width={width} height={height} data={data} />
    </div>
  );
}

export default PieChart;
