import { useSelector } from 'react-redux';

import { CLOSE_SVG_DARK, CLOSE_SVG_LIGHT } from 'assets/images';

function CloseButton({ closeHandle }) {
  const { Theme } = useSelector((state) => state.auth);
  return (
    <img
      src={Theme === 'light' ? CLOSE_SVG_DARK : CLOSE_SVG_LIGHT}
      alt="close"
      className="close_icon_modal cursor-pointer"
      onClick={closeHandle}
    />
  );
}

export default CloseButton;
