import { handleToastfunction } from './helperFunction';

export const concatString = (characters, string) => {
  if (string?.length >= characters) {
    return `${string.substring(0, characters)}...`;
  }
  return string;
};

export const replaceString = (arr, list) => {
  const newArr = arr.split(',').map((item) => item.trim());
  let tempString = '';
  for (const x of newArr) {
    if (Object.keys(list).includes(x)) {
      tempString += `${list[x]}, `;
    }
  }
  return tempString.substring(0, tempString.length - 2);
};

export const getChipCount = (item) => {
  let count = 0;
  item?.map((i) => {
    if (i?.chipQuantity) {
      return (count += parseInt(i?.chipQuantity));
    }
  });
  return count;
};

export const encodedString = (value) => encodeURIComponent(value);

export const copyToClipboard = (text, setCopied) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      handleToastfunction({ status: 'success', title: 'Access Code copied to clipboard' });
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    })
    .catch(() => {
      setCopied(false);
      handleToastfunction({ status: 'error', title: 'Unable to copy to clipboard' });
    });
};
