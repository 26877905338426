import { Col, Card, CardBody } from 'reactstrap';
import CountUp from 'react-countup';

function MiniWidget(props) {
  return (
    <>
      {props?.reports?.map((report) => (
        <Col md={6} xl={props?.xl ? props?.xl : 3} key={report?.id}>
          <Card>
            <CardBody id="data_card">
              <p className="text-muted mb-0">{report?.title}</p>
              {/* <div className="float-end mt-2">
              <img
                src={report?.graph === 'up' ? GRAPH_UP_SVG : GRAPH_DOWN_SVG}
                alt="graph"
                className="pr-3 graph_img"
              />
            </div> */}
              <div id="data_card_val">
                <h4 className="mb-1 mt-1">
                  <span>
                  <CountUp
                    end={report?.value}
                    separator=","
                    prefix={report?.prefix}
                    suffix={report?.suffix}
                    decimals={report?.decimal}
                    start={0.0}
                    duration={1}
                  />
                </span>
                </h4>
              </div>
              {report?.desc ? (
                <p className="text-muted mt-3 mb-0">
                  <span className={`text-${report?.color} me-1`}>
                  <i className={`${report?.icon} me-1`} />
                  {report?.badgeValue}
                </span>
                  {' '}
                  {report?.desc}
                </p>
              ) : (
                <p className="text-muted mt-3 pb-2" />
              )}
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  );
}

export default MiniWidget;
