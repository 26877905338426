import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from 'reactstrap';

import { CustomDateInputLog, SearchInput } from 'components';

function SearchRangeComponent({
  handleSearch = () => null,
  handleSearchRange = () => null,
  handleResetSearchRange = () => null,
  showSearchBar = false,
  searchBarPlaceHolder = 'Search...',
  minSearchChar = 2,
  flag,
}) {
  const searchRangeIntialValue = {
    startDate: '',
    endDate: '',
  };
  const [buttonText, setButtonText] = useState('Search Range');

  const handleDateRange = async (values, resetForm = () => null) => {
    if (values?.startDate && values?.endDate) {
      if (buttonText === 'Reset') {
        setButtonText('Search Range');
        await resetForm();
        handleResetSearchRange();
      } else {
        setButtonText('Reset');
        handleSearchRange(values);
      }
    }
  };
  return (
    <div className="d-flex search_range_component">
      <Formik
        initialValues={searchRangeIntialValue}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          handleDateRange(values, resetForm);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="d-flex wrapper form_div" id="search_date">
            <div className="d-flex align-items-center w-100 searchrange_div">
              {flag === 'User' ? ' ' : (
              <div className="d-flex align-items-center">
                <div className="wrapper">
                  {/* start date */}
                  <Field
                      component={CustomDateInputLog}
                      name="startDate"
                      value={(values.startDate && new Date(values.startDate)) || ''}
                      className="date_input_field mt_20 me-2 wrapper"
                      showMonth
                      showYear
                      placeholder="MM/DD/YYYY"
                      dateFormat="MM/dd/yyyy"
                      calendarClassName="calendar_icon"
                      minDate=""
                      maxDate={new Date()}
                      onchangeDateHandle={() => {
                        setFieldValue('endDate', '');
                        setButtonText('Search Range');
                      }}
                    />
                  <p className="mb-0">to</p>
                  {/* end date */}
                  <Field
                      component={CustomDateInputLog}
                      name="endDate"
                      value={(values.endDate && new Date(values.endDate)) || ''}
                      className="date_input_field mt_20 ms-2 me-3 wrapper"
                      showMonth
                      showYear
                      placeholder="MM/DD/YYYY"
                      dateFormat="MM/dd/yyyy"
                      calendarClassName="calendar_icon"
                      disabled={values.startDate === ''}
                      minDate={values.startDate}
                      maxDate={new Date()}
                      required={!!values.startDate}
                      onchangeDateHandle={() => setButtonText('Search Range')}
                    />
                </div>
                <Button
                  type="submit"
                  className="button-color nowrap"
                  disabled={!values?.startDate}
                >
                  {buttonText}
                </Button>
              </div>
            )}
              {/* search by value */}
              {showSearchBar && (
              <div className="wrapper justify-content-end ps-3 searchbar_div">
                <SearchInput
                      onChange={(e) => handleSearch(e)}
                      placeholder={searchBarPlaceHolder}
                      minSearchChar={minSearchChar}
                    />
              </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SearchRangeComponent;
