// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-toast {
  padding: 0 30px 0 0;
}

.title-toast {
  margin: 0;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.subtitle-toast {
  margin: 0;
  color: #8b8b8b;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.action-toast {
  margin: 8px 0 8px 0;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 128px;
  background: #2e2e2e;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.icon-toaster {
  margin-top: 6px;
}

.title-toast-black {
  color: black !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/toast.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,oBAAoB;EACpB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".custom-toast {\n  padding: 0 30px 0 0;\n}\n\n.title-toast {\n  margin: 0;\n  color: #fff;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 16px;\n}\n\n.subtitle-toast {\n  margin: 0;\n  color: #8b8b8b;\n  font-size: 11px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 16px;\n}\n\n.action-toast {\n  margin: 8px 0 8px 0;\n  display: flex;\n  padding: 8px 16px;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n  border-radius: 128px;\n  background: #2e2e2e;\n  border: none;\n  color: #fff;\n  text-align: center;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 16px;\n}\n\n.icon-toaster {\n  margin-top: 6px;\n}\n\n.title-toast-black {\n  color: black !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
