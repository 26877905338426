import {
  Button, Modal, ModalBody, ModalHeader,
} from 'react-bootstrap';

import { SUCCESS_ICON } from 'assets/images';
import './index.css';

function SuccessModal({
  showModal, closeModal, title, buttonText,
}) {
  return (
    <Modal
      id="success_modal"
      className="text-center"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
    >
      <ModalHeader>
        <h4>{title}</h4>
      </ModalHeader>
      <ModalBody>
        <img src={SUCCESS_ICON} alt="close" />
        <Button className="w-100 cursor-pointer mt-5" onClick={() => closeModal()}>
          {buttonText}
        </Button>
      </ModalBody>
    </Modal>
  );
}

export default SuccessModal;
