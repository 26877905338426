// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side_bar_top {
  border-bottom: none;
  box-shadow: none;
}
.logo .logo-sm {
  display: none;
}

nav {
  border-right: none;
  box-shadow: none;
  background-color: var(--sidebar-color);
}
.sidebar-nav-item {
  border-bottom: 1px solid var(--sidebar-border-bottom);
}
.sidebar-nav-item a {
  text-decoration: none;
}
.active_nav_tab {
  display: flex;
  font-size: 15px;
  color: var(--text-primary);
}
#closemenu_svg svg,
.inactive_nav_tab:hover svg {
  stroke: #1a96f0;
}
#closemenu_svg .sidebar-name,
.inactive_nav_tab:hover {
  color: #1a96f0;
}
.active_nav_tab svg,
.inactive_nav_tab svg {
  stroke: var(--text-primary);
}
.inactive_nav_tab {
  display: flex;
  font-weight: 400;
  font-size: 15px;
  color: var(--text-primary);
}
.lh70 {
  line-height: 70px;
}
.pl10 {
  padding-left: 10px;
}
@media (max-width: 991.98px) {
  .logo span.logo-sm {
    display: inline-;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/Layout/SideBar.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,sCAAsC;AACxC;AACA;EACE,qDAAqD;AACvD;AACA;EACE,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,eAAe;EACf,0BAA0B;AAC5B;AACA;;EAEE,eAAe;AACjB;AACA;;EAEE,cAAc;AAChB;AACA;;EAEE,2BAA2B;AAC7B;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,0BAA0B;AAC5B;AACA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;AACA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".side_bar_top {\n  border-bottom: none;\n  box-shadow: none;\n}\n.logo .logo-sm {\n  display: none;\n}\n\nnav {\n  border-right: none;\n  box-shadow: none;\n  background-color: var(--sidebar-color);\n}\n.sidebar-nav-item {\n  border-bottom: 1px solid var(--sidebar-border-bottom);\n}\n.sidebar-nav-item a {\n  text-decoration: none;\n}\n.active_nav_tab {\n  display: flex;\n  font-size: 15px;\n  color: var(--text-primary);\n}\n#closemenu_svg svg,\n.inactive_nav_tab:hover svg {\n  stroke: #1a96f0;\n}\n#closemenu_svg .sidebar-name,\n.inactive_nav_tab:hover {\n  color: #1a96f0;\n}\n.active_nav_tab svg,\n.inactive_nav_tab svg {\n  stroke: var(--text-primary);\n}\n.inactive_nav_tab {\n  display: flex;\n  font-weight: 400;\n  font-size: 15px;\n  color: var(--text-primary);\n}\n.lh70 {\n  line-height: 70px;\n}\n.pl10 {\n  padding-left: 10px;\n}\n@media (max-width: 991.98px) {\n  .logo span.logo-sm {\n    display: inline-;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
