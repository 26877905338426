/*eslint-disable */
import React from 'react';
import {
  PLATFORM,
  DASHBOARD,
  PROJECT,
  USERS,
  TRANSACTION,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  ABOUT_US,
} from 'assets/images';

const DataItemsAdmin = t => [
  {
    name: t('Dashboard'),
    link: '/dashboard',
    tab: 'dashboard',
    logo: <DASHBOARD className="me-2 sidebar-icon" />,
  },
  {
    name: t('Projects'),
    link: '/project',
    tab: 'folder',
    logo: <PROJECT className="me-2 sidebar-icon" />,
  },
  {
    name: t('Users'),
    link: '/users',
    tab: 'chips-management',
    logo: <USERS className="me-2 sidebar-icon" />,
  },
  {
    name: t('Transactions'),
    link: '/transactions',
    tab: 'transactions',
    logo: <TRANSACTION className="me-2 sidebar-icon" />,
  },
  {
    name: t('Platform Variable'),
    link: '/platform-variable',
    tab: 'platforms',
    logo: <PLATFORM className="me-2 sidebar-icon" />,
  },
  {
    name: t('Affiliate'),
    link: '/affiliate',
    tab: 'affiliate',
    logo: <ABOUT_US className="me-2 sidebar-icon" />,
  },
  {
    name: t('Swap'),
    link: '/swap-transaction',
    tab: 'swap',
    logo: <ABOUT_US className="me-2 sidebar-icon" />,
  },
  {
    name: t('Terms of Use'),
    link: '/terms-of-use',
    tab: 'termsOfUse',
    logo: <TERMS_OF_USE className="me-2 sidebar-icon" />,
  },
  {
    name: t('Privacy Policy'),
    link: '/privacy-policy',
    tab: 'privacyPolicy',
    logo: <PRIVACY_POLICY className="me-2 sidebar-icon" />,
  },
  {
    name: t('About Us'),
    link: '/about-us',
    tab: 'aboutUs',
    logo: <ABOUT_US className="me-2 sidebar-icon" />,
  }, 
];

export default DataItemsAdmin;
