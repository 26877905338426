import { put } from 'redux-saga/effects';
import errorHandler from 'utils/apiHandler';
import {
  userListSagaSuccess,
  userListSagaFail,
  userOverviewSagaSuccess,
  userOverviewSagaFail,
  userExportListSagaSuccess,
  userExportListSagaFail,
  userDetailSagaSuccess,
  userDetailSagaFail,
  investorDetailSagaSuccess,
  investorDetailSagaFail,
  creatorDetailSagaSuccess,
  creatorDetailSagaFail,
  blockUserSagaSuccess,
  blockUserSagaFail,
  suspendUserSagaSuccess,
  suspendUserSagaFail,
  blockSuspListSagaSuccess,
  blockSuspListSagaFail,
  expblockSuspListSagaSuccess,
  expblockSuspListSagaFail,
  deleteUserSagaSuccess,
  deleteUserSagaFail,
} from 'store/actions';
import { handleToastfunction } from 'utils';
import {
  GET_USER_LIST_URL,
  USER_OVERVIEW_URL,
  USER_EXPORT_LIST_URL,
  USER_DETAILS_URL,
  INVESTOR_DETAILS_URL,
  CREATOR_DETAILS_URL,
  BLOCK_USER_URL,
  SUSPEND_USER_URL,
  SUSPEND_BLOCK_USER_LIST_URL,
  EXP_SUSPEND_BLOCK_LIST_URL,
  DELETE_USER_URL,
} from 'apis';

export function* userListSaga({ payload }) {
  const { itemsPerPage, startIndex, search, userType, status, sortby, sortValue } = payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (status) {
    params.status = status;
  }
  if (sortby) {
    params.sortBy = sortby;
    params.sortValue = sortValue;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${GET_USER_LIST_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(userListSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(userListSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* userOverviewSaga({ payload }) {
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${USER_OVERVIEW_URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(userOverviewSagaSuccess(data));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(userOverviewSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* userExportListSaga({ payload }) {
  const { search, userType } = payload;
  const params = {};
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${USER_EXPORT_LIST_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(userExportListSagaSuccess(data));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(userExportListSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* userDetailSaga({ payload }) {
  const { id } = payload;
  const params = {};
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${USER_DETAILS_URL}${id}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(userDetailSagaSuccess(data));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(userDetailSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* investorDetailSaga({ payload }) {
  const { itemsPerPage, startIndex, search, userType, id, startDate, endDate, sortby, sortValue } = payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate || endDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (sortby) {
    params.sortBy = sortby;
    params.sortValue = sortValue;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${INVESTOR_DETAILS_URL}${id}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(investorDetailSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(investorDetailSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* creatorDetailSaga({ payload }) {
  const { itemsPerPage, startIndex, search, userType, id, startDate, endDate, sortby, sortValue } = payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate || endDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (sortby) {
    params.sortBy = sortby;
    params.sortValue = sortValue;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${CREATOR_DETAILS_URL}${id}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(creatorDetailSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(creatorDetailSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* blockUserSaga({ payload }) {
  const { blockUser, id, blockreason } = payload;
  const params = {
    isBlocked: blockUser,
    reason: blockreason,
  };
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${BLOCK_USER_URL}${id}`,
    payload: params,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(blockUserSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(blockUserSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'patch',
    token: true,
  });
}

export function* suspendUserSaga({ payload }) {
  const { suspendUser, id, blockreason } = payload;
  const params = {
    isSuspended: suspendUser,
    reason: blockreason,
  };
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${SUSPEND_USER_URL}${id}`,
    payload: params,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(suspendUserSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(suspendUserSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'patch',
    token: true,
  });
}

export function* blockSuspListSaga({ payload }) {
  const { itemsPerPage, startIndex, search, userType, status } = payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (status) {
    params.status = status;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${SUSPEND_BLOCK_USER_LIST_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(blockSuspListSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(blockSuspListSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* expblockSuspListSaga({ payload }) {
  const { search, userType, status } = payload;
  const params = {};
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (status) {
    params.status = status;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${EXP_SUSPEND_BLOCK_LIST_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(expblockSuspListSagaSuccess(data));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(expblockSuspListSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* deleteUserSaga({ payload }) {
  const { id, isDelete, deletedReason } = payload;
  const param = {
    isDeleted: isDelete,
    deleteReason: deletedReason,
  };
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${DELETE_USER_URL}${id}`,
    payload: param,
    successHandler: yield function* (response) {
      const { data } = response;
      yield handleToastfunction({ status: 'success', title: response?.message });
      yield put(deleteUserSagaSuccess({ response }));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(deleteUserSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'patch',
    token: true,
  });
}
