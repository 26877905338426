// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.position-abs {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #939090;
}
.password-eye-icon {
  background-color: transparent;
  right: 8px;
}
.each_input {
  margin-bottom: 30px;
}
.calender_icon {
  position: absolute;
  right: 10px;
  top: 8px;
}
.pw_eye {
  top: 7px;
  position: absolute;
  right: 13px;
}
#custom_target .custom-select.is-invalid,
#custom_target .form-control.is-invalid,
#custom_target .was-validated .custom-select:invalid,
#custom_target .was-validated .form-control:invalid {
  border: 1px solid #dc3545 !important;
  background-image: none;
}
#custom_target .custom-select.is-invalid:focus,
#custom_target .form-control.is-invalid:focus,
#custom_target .was-validated .custom-select:invalid:focus,
#custom_target .was-validated .form-control:invalid:focus {
  border: 1px solid #dc3545 !important;
  background-image: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/customComponent/index.css"],"names":[],"mappings":";;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,cAAc;AAChB;AACA;EACE,6BAA6B;EAC7B,UAAU;AACZ;AACA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;AACV;AACA;EACE,QAAQ;EACR,kBAAkB;EAClB,WAAW;AACb;AACA;;;;EAIE,oCAAoC;EACpC,sBAAsB;AACxB;AACA;;;;EAIE,oCAAoC;EACpC,sBAAsB;AACxB","sourcesContent":["\n\n.position-abs {\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  color: #939090;\n}\n.password-eye-icon {\n  background-color: transparent;\n  right: 8px;\n}\n.each_input {\n  margin-bottom: 30px;\n}\n.calender_icon {\n  position: absolute;\n  right: 10px;\n  top: 8px;\n}\n.pw_eye {\n  top: 7px;\n  position: absolute;\n  right: 13px;\n}\n#custom_target .custom-select.is-invalid,\n#custom_target .form-control.is-invalid,\n#custom_target .was-validated .custom-select:invalid,\n#custom_target .was-validated .form-control:invalid {\n  border: 1px solid #dc3545 !important;\n  background-image: none;\n}\n#custom_target .custom-select.is-invalid:focus,\n#custom_target .form-control.is-invalid:focus,\n#custom_target .was-validated .custom-select:invalid:focus,\n#custom_target .was-validated .form-control:invalid:focus {\n  border: 1px solid #dc3545 !important;\n  background-image: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
