// images(png/jpg)
export { default as DANGER_ICON_SVG } from './danger_icon.svg';
export { default as EYE_SVG } from './eye_icon.svg';
export { default as EYE_SLASH_SVG } from './password_eye.svg';
export { default as CALENDAR_ICON } from './calendar.png';
export { default as CLOSE } from './close.svg';
export { default as SUCCESS_ICON } from './success_icon.svg';
export { default as BACK_ICON_SVG } from './back.svg';
export { default as EDIT_ICON } from './Edit_icon.svg';
export {default as BITCOIN} from "./bitcoin.png"
export {default as CONTACT} from "./contact.png"
export {default as ARCHIVE} from "./archive.png"
// export {default as TOTAL_USER} from "./totalUser.png"
export {default as CLOSED} from "./closed.png"
export {default as DUMMY_IMAGE} from "./dummyImage.png"
export {default as DUMMY_USER_IMAGE} from "./dummyUser.png"
export {default as TWITTER} from "./twitter.svg"
export {default as TELEGRAM} from "./telegram.svg"
export {default as INSTAGRAM} from "./instagram.svg"
export {default as FACEBOOK} from "./facebook.svg"
export {default as LINK} from "./link.png"
export {default as DROPDOWN_ICON} from "./dropdownIcon.svg"
export { default as CRYPTO_LOGO } from './crypto-logo.png';
export { default as UPLOAD_PNG } from './upload.png';
export { default as BACK_ICON_LIGHT } from './back_icon_white.svg';
export { default as CLOSE_SVG_DARK } from './Close_svg.svg';
export { default as CLOSE_SVG_LIGHT } from './Close_svg_white.svg';
export { default as DELETE_ICON } from './delete_icon.svg';
export {default as UNREAD_NOTIFICATION} from "./unread_notifi.png"

// export { ReactComponent as MEETING} from "./sidebar-icon/Meeting.svg"
export { ReactComponent as ABOUT_US} from "./sidebar-icon/aboutUs.svg"
export { ReactComponent as PLATFORM} from "./sidebar-icon/platform.svg"
export { ReactComponent as USERS} from "./sidebar-icon/Users.svg"
export { ReactComponent as TRANSACTION} from "./sidebar-icon/Transactions.svg"
export { ReactComponent as TERMS_OF_USE} from "./sidebar-icon/termsOfUse.svg"
export { ReactComponent as PRIVACY_POLICY} from "./sidebar-icon/privacyPolicy.svg"
export { ReactComponent as PROJECT} from "./sidebar-icon/project.svg"
export {ReactComponent as DASHBOARD} from "./sidebar-icon/dashboard.svg"
export {default as TOTAL_USER} from "./totalUser.png"
export {default as COMPLETED} from "./crypto-icon/Completed.png"
export {default as FAILED} from "./crypto-icon/Failed.png"
export {default as LIVE_PROJECT} from "./crypto-icon/LiveProject.png"
export {default as NEW_PROJECT} from "./crypto-icon/NewProject.png"
export {default as PENDING_TO_LIST} from "./crypto-icon/Pendingtolist.png"
export {default as REJECTED} from "./crypto-icon/Rejected.png"
export { default as MATIC } from './matic.svg';
export { default as DUMMY_PROFILE } from './dummyProfile.jpg';
export { default as CHECK_ICON } from './icon-check.png';
export { default as COPY } from './copy.svg';


