// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-header {
  background: var(--sidebar-color);
}
.bars_color {
  color: var(--text-primary);
}
#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 250px;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
}
.navbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 0.625rem 0 0;
}
`, "",{"version":3,"sources":["webpack://./src/views/Layout/header/header.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,eAAe;EACf,MAAM;EACN,QAAQ;EACR,WAAW;EACX,UAAU;EACV,sBAAsB;EACtB,4CAA4C;AAC9C;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,YAAY;EACZ,uBAAuB;AACzB","sourcesContent":[".navbar-header {\n  background: var(--sidebar-color);\n}\n.bars_color {\n  color: var(--text-primary);\n}\n#page-topbar {\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 250px;\n  z-index: 2;\n  background-color: #fff;\n  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);\n}\n.navbar-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 0 auto;\n  height: 70px;\n  padding: 0 0.625rem 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
