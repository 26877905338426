/* eslint-disable */
import { all, takeLatest } from 'redux-saga/effects';
import * as actionLabels from '../actionLabels';
import {
  loginSaga,
  forgotAuthenticatorSaga,
  languageChooseSaga,
} from './auth/auth';
import {
  dashboardGraphSaga,
  dashboardOverviewSaga,
  dashboardStatisticsSaga,
} from './dashboard/dashboard';
import {
  blockSuspListSaga,
  blockUserSaga,
  creatorDetailSaga,
  deleteUserSaga,
  expblockSuspListSaga,
  investorDetailSaga,
  suspendUserSaga,
  userDetailSaga,
  userExportListSaga,
  userListSaga,
  userOverviewSaga,
} from './user/user';
import {
  deleteProjectListSaga,
  deleteProjectSaga,
  getProjectDetailsSaga,
  projectApprovalSaga,
  projectListSaga,
  projectOverviewSaga,
  projectRejectionSaga,
  updateProjectSaga,
  uploadImageSaga,
} from './project/project';
import { userInfoSaga } from './profile/profile';
import { getPlatformVariableSaga, platformHistorySaga } from './platform/platform';
import {
  exportListingFeeSaga,
  exportPlatformFeeSaga,
  exportTokenGenerationFeeSaga,
  getListingFeeSaga,
  getPlatformFeeSaga,
  getTokenGenerationFeeSaga,
  getUserListSaga,
  transactionExportListSaga,
} from './transaction/transaction';
import { clearNotificationSaga } from './notification/notification';
import { getCmsDetailSaga, updateCmsSaga } from './cms/cms';
import { exportAffiliateInvestment, getAffiliateDetails, getAffiliateInfo, getAffiliateInvestment, getAffiliateList } from './affiliate/affiliate';
import { getSwapList } from './swap/swap';

export function* watchAuthentication() {
  yield all([
    takeLatest(actionLabels.LOGIN_SAGA, loginSaga),
    takeLatest(actionLabels.FORGOT_AUTHENTICATOR_SAGA, forgotAuthenticatorSaga),
    takeLatest(actionLabels.LANGUAGE_CHOOSE_SAGA, languageChooseSaga),
  ]);
}

export function* watchDashboard() {
  yield all([
    takeLatest(actionLabels.DASHBOARD_OVERVIEW, dashboardOverviewSaga),
    takeLatest(actionLabels.DASHBOARD_STATISTICS, dashboardStatisticsSaga),
    takeLatest(actionLabels.DASHBOARD_GRAPH, dashboardGraphSaga),
  ]);
}

export function* watchUser() {
  yield all([
    takeLatest(actionLabels.USER_LIST, userListSaga),
    takeLatest(actionLabels.USER_OVERVIEW, userOverviewSaga),
    takeLatest(actionLabels.USER_EXPORT_LIST, userExportListSaga),
    takeLatest(actionLabels.USER_DETAILS, userDetailSaga),
    takeLatest(actionLabels.INVESTOR_DETAILS, investorDetailSaga),
    takeLatest(actionLabels.CREATOR_DETAILS, creatorDetailSaga),
    takeLatest(actionLabels.BLOCK_USER, blockUserSaga),
    takeLatest(actionLabels.SUSPEND_USER, suspendUserSaga),
    takeLatest(actionLabels.SUSPEND_BLOCK_USER_LIST, blockSuspListSaga),
    takeLatest(actionLabels.EXP_BLOCK_SUSPEND_LIST, expblockSuspListSaga),
    takeLatest(actionLabels.DELETE_USER, deleteUserSaga),
  ]);
}

export function* watchProject() {
  yield all([
    takeLatest(actionLabels.PROJECT_OVERVIEW, projectOverviewSaga),
    takeLatest(actionLabels.PROJECT_LIST, projectListSaga),
    takeLatest(actionLabels.PROJECT_APPROVAL, projectApprovalSaga),
    takeLatest(actionLabels.PROJECT_REJECT, projectRejectionSaga),
    takeLatest(actionLabels.GET_PROJECT_DETAILS, getProjectDetailsSaga),
    takeLatest(actionLabels.UPLOAD_IMAGE, uploadImageSaga),
    takeLatest(actionLabels.UPDATE_PROJECT, updateProjectSaga),
    takeLatest(actionLabels.DELETE_PROJECT_LIST, deleteProjectListSaga),
    takeLatest(actionLabels.DELETE_PROJECT, deleteProjectSaga),
  ]);
}

export function* watchProfile() {
  yield all([takeLatest(actionLabels.USER_INFO, userInfoSaga)]);
}

export function* watchPlatform() {
  yield all([
    takeLatest(actionLabels.GET_PLATFORM_VARIABLE, getPlatformVariableSaga),
    takeLatest(actionLabels.PLATFORM_HISTORY, platformHistorySaga),
  ]);
}

export function* watchTransaction() {
  yield all([
    takeLatest(actionLabels.GET_TOKEN_GENERATION_FEE, getTokenGenerationFeeSaga),
    takeLatest(actionLabels.GET_PLATFORM_FEE_LIST, getPlatformFeeSaga),
    takeLatest(actionLabels.GET_LISTING_FEE_LIST, getListingFeeSaga),
    takeLatest(actionLabels.GET_USER_LIST, getUserListSaga),
    takeLatest(actionLabels.TRANSACTION_EXPORT_LIST, transactionExportListSaga),
    takeLatest(actionLabels.EXPORT_LISTING_FEE, exportListingFeeSaga),
    takeLatest(actionLabels.EXPORT_TOKENGENERATION_FEE, exportTokenGenerationFeeSaga),
    takeLatest(actionLabels.EXPORT_PLATFORM_FEE, exportPlatformFeeSaga),
  ]);
}

export function* watchNotification() {
  yield all([takeLatest(actionLabels.CLEAR_NOTIFICATION, clearNotificationSaga)]);
}

export function* watchCms() {
  yield all([takeLatest(actionLabels.UPDATE_CMS, updateCmsSaga)]);
  yield all([takeLatest(actionLabels.GET_CMS_DETAILS, getCmsDetailSaga)]);
}

export function* watchAffiliate() {
  yield all([takeLatest(actionLabels.GET_AFFILIATE_LIST, getAffiliateList)]);
  yield all([takeLatest(actionLabels.GET_AFFILIATE_INFO, getAffiliateInfo)]);
  yield all([takeLatest(actionLabels.GET_AFFILIATE_DETAILS, getAffiliateDetails)]);
  yield all([takeLatest(actionLabels.GET_AFFILIATE_INVESTMENT, getAffiliateInvestment)]);
  yield all([takeLatest(actionLabels.EXPORT_AFFILIATE_INVESTMENT, exportAffiliateInvestment)]);
}

export function* watchSwap() {
  yield all([takeLatest(actionLabels.GET_SWAP_TRANSC_LIST, getSwapList)]);
}
