const jpTranslation = {
  Dashboard: 'ダッシュボード',
  Overview: '概要',
  'Total Investments': '投資総額',
  'Total Users': '総ユーザー数',
  'New User': '新規ユーザー',
  'Total Projects': 'プロジェクト総数',
  'Pending Project for Approval': '承認待ちプロジェクト',
  Statistics: '統計',
  'Top Project Investment Summary': 'トップ・プロジェクト投資概要',
  Creator: 'プロジェクト作成者',
  Investor: '投資家',
  'Total Amount to be raised': '調達総額',
  'Fund raised': '調達済み',
  'View all': '全てを見る',
  'Sr. no': '番号',
  Actions: 'アクション',
  'Project Tracking': 'プロジェクト追跡',
  'New Project Request': '新規プロジェクト申請',
  Open: 'オープン',
  Closed: '終了',
  Completed: '完了',
  Failed: '失敗',
  'Creator Name': '作成者名',
  'Project name': 'プロジェクト名',
  'Creation date': '作成日',
  'Approve project': 'プロジェクト承認',
  'Reject project': 'プロジェクト却下',
  'Approved project': '承認されたプロジェクト',
  View: '表示',
  Approve: '承認',
  Reject: '却下',
  Edit: '編集',
  Search: '検索...',
  'Start date': '開始日',
  'End date': '終了日',
  'Rows per page': 'ページ行数',
  Users: 'ユーザー',
  'Active Creator': 'アクティブプロジェクト作成者',
  'Active Investors': 'アクティブ投資家',
  'Full Name': 'フルネーム',
  Prev: '前へ',
  Next: '次へ',
  Export: 'エクスポート',
  'Sort by: User': '並べ替え ユーザー',
  'All users': 'すべてのユーザー',
  Transactions: '取引',
  Admin: '管理者',
  User: 'ユーザー',
  'Listing Fee': 'リスティング料金',
  'Token Generation Fee': 'トークン発行手数料',
  'Platform Fee': 'プラットフォーム料金',
  'Listing Fee Transaction Hash': 'リスティング手数料取引番号',
  'Time Stamp': '日時',
  'Transaction Type': '取引タイプ',
  Amount: '金額',
  'Platform Percentage': 'プラットフォーム・パーセンテージ:',
  Update: '更新',
  'Enter Matic': 'MATICを入力',
  'Enter Fee': '手数料を入力',
  'Date and Time': '日時',
  'Old Percentage': '旧割合',
  'New Percentage': '新割合',
  'Old Listing Fee': '旧料金',
  'There are no records to display': '表示する履歴はありません',
  Suspend: '一時停止',
  Block: 'ブロック',
  'Admin Profile': '管理者プロフィール',
  'Email Id': 'メールアドレス',
  'Phone Number': '電話番号',
  'New Listing Fee': '新リスティング手数料',
  'Old Token Generation Fee': '旧トークン発行手数料',
  'New Token Generation Fee': '新トークン発行手数料',
  'Project Name': 'プロジェクト名',
  'Project testing for notification': '通知用プロジェクト・テスト',
  'Project Description': 'プロジェクト概要',
  'Description here': '概要はこちら',
  'Project Website': 'プロジェクトのウェブサイト',
  'Soft Cap': '最小調達額',
  'Hard Cap': '総合調達額',
  'Social Media Links': 'ソーシャルメディアリンク',
  Duration: '活動期間',
  Days: '日数',
  'Team Details': 'チーム詳',
  'Listed Projects': 'プロジェクト一覧',
  'Token Name': 'トークン名',
  'Team Member Name': 'チームメンバー名',
  'Team Member Role': 'チームメンバーの役割',
  'Other Supporting Documents': 'その他の書類',
  'Token Address': 'トークンアドレス',
  'Token Supply': 'トークン発行数',
  'Token Price': 'トークン価格',
  'Token Distribution Plan': 'トークン配布計画',
  'Road Map': 'ロードマップ',
  Description: 'トークンの説明',
  Date: '日付',
  'Token Details': 'トークンの詳細',
  'Team Member Picture': 'チームメンバーの写真',
  'Token Symbol': 'トークン・シンボル',
  'Other Documents': 'その他の資料',
  Cancel: 'キャンセル',
  Save: 'アップロード',
  Upload: 'アップロード',
  'Project Details': 'プロジェクト詳細',
  'User Name': 'ユーザー名',
  Feedback: 'コメント',
  Projects: 'プロジェクト',
  'Platform Variable': 'プラットフォーム変数',
  'Project Pending to List': 'リストへの登録保留中のプロジェクト',
  Deleted: '削除済み',
  'Listed Date': '掲載日',
  'Blockchain Status': 'ブロックチェーンステータス',
  'Transaction Hash': '取引ハッシュ',
  'Total Amount': '合計金額',
  'Old Variable': '旧変数',
  'New Variable': '新しい変数',
  From: 'から',
  To: 'に',
  'ICO End Date': 'ICO  終了日',
  'Funding Status': '資金調達  状態',
  'Approval Date': '承認  日付',
  'Project Creation Date': 'プロジェクト作成日',
  Status: '状態',
  'Transaction Hash & Timestamp': '取引ハッシュ   そして   タイムスタンプ',
  'Platform percentage hash': 'プラットフォームのパーセンテージハッシュ',
  'Number of Investments': '投資数',
  pending: '未定',
  'Amount Raised': '調達額',
  'Soft cap fail': 'ソフトキャップに失敗',
  'In Progress': '経過中',
  'Min. Investment': '最低投資額',
  Progress: '経過',
  Participants: '投資家数',
  OK: 'わかりました',
  'Change Password': 'パスワードを変更する',
  Logout: 'ログアウト',
  'Connect Wallet': 'ウォレット接続',
  'Are you sure you want to approve ?': '本当に承認してもよろしいですか?',
  'Platform Transaction Hash Code': 'プラットフォームトランザクションハッシュコード',
  Timestamp: 'タイムスタンプ',
  'Are you sure you want to logout?': 'ログアウトしてもよろしいですか?',
  'Log In': 'Log In',
  Notification: '通知',
  'Email address': 'メールアドレス',
  'User Type': 'ユーザータイプ',
  'Clear All': 'すべてクリア',
  'Sort by': '並び替え',
  'Old to New': '古いものから新しいものへ',
  'New to Old': '新しいものから古いものまで',
  Reactivate: '再アクティブ化する',
  Unblock: 'ブロックを解除する',
  'Terms of Use': '利用規約',
  'Privacy Policy': 'プライバシーポリシー',
  'About Us': '私たちについて',
  'Live Projects': 'ライブプロジェクト',
  'Completed Projects': '完了したプロジェクト',
  'Rejected Projects': '拒否されたプロジェクト',
  'Failed Projects': '失敗したプロジェクト',
  'Deleted Projects': '削除されたプロジェクト',
  'Are you sure you want to block user ?': 'ユーザーをブロックしてもよろしいですか?',
  'Are you sure you want to suspend user ?': 'ユーザーを一時停止してもよろしいですか ?',
  'Enter Description': '説明を入力してください',
  'Blocked User': 'ブロックされたユーザー',
  'Suspended User': '停止されたユーザー',
  Email: '電子メール',
  'Registration Timestamp': '登録タイムスタンプ',
  'Project Name is required': 'プロジェクト名は必須です',
  'Minimum 2 Characters': '最低 2 文字',
  'Maximum 15 Characters': '最大15文字',
  'Project description is required': 'プロジェクトの説明は必須です',
  'Must have atLeast 2 characters': '少なくとも 2 文字が必要です',
  'Cannot exceed 100 characters': '100文字を超えることはできません',
  'Project Duration is required': 'プロジェクト期間は必須です',
  'Soft cap must be a number': 'ソフトキャップは数字でなければなりません',
  'Soft cap must be a positive number': 'ソフトキャップは正の数でなければなりません',
  'Soft cap is required': 'ソフトキャップが必要です',
  'Hard cap must be a number': 'ハードキャップは数値でなければなりません',
  'Hard cap must be a positive number': 'ハードキャップは正の数である必要があります',
  'Hard cap is required': 'ハードキャップが必要です',
  'Hard cap must be greater than soft cap':
    'ハードキャップはソフトキャップより大きくなければなりません',
  'Token price is required': 'トークン価格は必須です',
  'Token price must be a positive number': 'トークン価格は正の数である必要があります',
  'Distribution plan is required': '配布計画は必須です',
  'Lockup period is required': 'ロックアップ期間が必要です',
  'Invalid URL': '無効なURL',
  'Project Website is required': 'プロジェクトの Web サイトは必須です',
  'Min Investment must be a number': '最小投資は数値でなければなりません',
  'Min Investment must be a positive number': '最小投資は正の数である必要があります',
  'Min Investment is required': '最低投資が必要です',
  'Min Investment must be less than hard cap': '最小投資はハードキャップ未満である必要があります',
  'Old Password': '以前のパスワード',
  'New Password': '新しいパスワード',
  'Confirm Password': 'パスワードを認証する',
  'At least 8 characters with atleast 1 uppercase and 1 lowercase letters.':
    '少なくとも 1 つの大文字と 1 つの小文字を含む 8 文字以上。',
  'A mixture of letters and Numbers.': '文字と数字の組み合わせ。',
  'Inclusion of at least one special character.': '少なくとも 1 つの特殊文字が含まれていること。',
  'NOTE : Change password in every 90 Days to increase security':
    '注: セキュリティを強化するために、90 日ごとにパスワードを変更してください',

  'Old Password is required': '古いパスワードが必要です',
  'New Password is required': '新しいパスワードが必要です',
  'Confirm Password is required': 'パスワードが必要であることを確認してください',
  'It must have atleast 8 characters, 1 Uppercase, 1 Lowercase, 1 digit & 1 special character':
    '「少なくとも 8 文字、大文字 1 文字、小文字 1 文字、数字 1 文字、特殊文字 1 文字を含む必要があります」',
  'Passwords must match': 'パスワードが一致する必要があります',
  Back: '戻る',
  ReadAll: 'すべて読む',
  PleaseConnectTheWallet: 'ウォレットを接続してください',
  ErrorDeletingProject: 'プロジェクトの削除エラー',
  TransactionRejectedByUser: 'ユーザーによってトランザクションが拒否されました',
  ProjectDeletedSuccessfully: 'プロジェクトは正常に削除されました',
  EnterValidFeeAmount: '有効な手数料金額を入力してください',
  ErrorUpdatingTgeFee: '料金の更新中にエラーが発生しました',
  FeeUpdatedSuccessfully: '料金が正常に更新されました',
  ErrorUpdatingTokenFee: 'トークン料金の更新エラー',
  ErrorUpdatingPlatformFee: 'プラットフォーム料金の更新エラー',
  onlyWholeNumbersAreAllowed: '整数のみが許可されます',
  PleaseConnectWithYourRegisteredWallet: '登録したウォレットに接続してください。',
  'Cannot exceed 200 characters': '200文字を超えることはできません',
  'Are you sure you want to delete project?': 'プロジェクトを削除してもよろしいですか?',
  RoadMapDocuments: 'ロードマップ文書',
  Month: '月',
  Months: '月',
  Year: '年',
  None: 'なし',
  LockupPeriod: 'ロックアップ期間',
  Twitter: 'ツイッター',
  Facebook: 'フェイスブック',
  Instagram: 'インスタグラム',
  Telegram: '電報',
  ProjectApprovedSuccessfully: 'プロジェクトは正常に承認されました。',
  ProjectIsNotApproved: 'プロジェクトは承認されていません',
  WalletAddress: 'ウォレットアドレス',
  ErrorUpdatingListingFee: '出品料金の更新エラー',
  Claim: '請求',
  Invested: '投資済み',
  affiliateName: 'アフィリエイト名',
  affiliateList: 'アフィリエイトリスト',
  affiliateDetails: 'アフィリエイトの詳細',
  Percentage: 'パーセンテージ',
  referralCode: '紹介コード',
  Wallet: '財布',
  investorDetail: '投資家の詳細',
  creatorDetail: 'クリエイター詳細',
  affiliateShare: 'アフィリエイトシェア',
  investorName: '投資家名',
  amountInvested: '投資額',
  affiliateIvestment: 'アフィリエイト投資',
  Affiliate: 'アフィリエイト',
  NoNotification: '通知なし',
  Send: '送信',
  enterDescription: '説明を入力してください',
  descriptionIsRequired: '説明は必須です',
  projectDetail: 'プロジェクトの詳細',
  'Description must be at most 250 characters': '説明は最大 250 文字にする必要があります',
  No: 'いいえ ',
  Yes: 'はい',
  youtubeLink: 'ユーチューブリンク',
  'Sort by: Status': '並べ替え条件: ステータス',
  All: '全て',
  Pending: '保留中',
  Successful: '成功',
  Exchange: '交換',
  fromToken: 'トークンから',
  fromTokenAmount: 'トークン金額から',
  toToken: 'トークンへ',
  toTokenAmount: 'トークン量へ',
  transactionType: '取引タイプ',
  transactionAddress: '取引アドレス',
  fromNetwork: 'ネットワークから',
  toNetwork: 'ネットワークへ',
  Commission: '手数料 (%)',
  PartnersFee: 'パートナー料金',
  SwapList: 'スワップリスト',
  Swap: 'スワップ',
  'Do you really want to Delete': '本当に削除しますか',
  activeStatus: 'アクティブなプロジェクト/投資があるため、削除は許可されません。',
 'Do you want to Block the user instead?': '代わりにユーザーをブロックしますか?',
 'and the user is already blocked': 'そしてユーザーはすでにブロックされています。',
 Delete: '消去',
 investments: '投資',
 projects: 'プロジェクト',
 'has active': 'アクティブです',
 'delete is not allowed': '削除は許可されていません',
};

export default jpTranslation;
